$(function () {
  "use strict";
  $.ajaxSetup({
    cache: false,
    data: {
      csrftoken: Cookies.get(cookie_pre + "csrfcookie"),
    },
  });

  if ($("[data-equalizer]").length > 0) {
    $(window).resize(function () {
      $("[data-equalizer]").foundation();
    });
  }

  //THIS IS A DEBUG FIX FOR OLD IE VERSIONS WHRE CONSOLE IS NOT INCLUDED
  if (!window.console) console = { log: function () {} };

  init_date_picker();
  init_hamburger();
  init_multiselect();

  //========================================================//
  // COPY LINK TO CLIPBOARD
  //========================================================//
  $(".main_content,#myModal").on("click", ".copyLink", function (e) {
    e.preventDefault();
    let id = $(this).attr("data-id");

    let value = $("#" + id).html();

    setClipboard(value);
  });

  //clear form
  $(".main_content").on("click", "#btnClearSearch", function () {
    // update the text on dropdowns
    $(".ms-options-wrap button").each(function () {
      $(this).text("please select");
    });
    //clear search input fields
    $(".ms-search input").each(function () {
      $(this).val("");
    });
    //show all options in case they had been hidden with search filter
    $(".ms-reflow").removeClass("selected").show();
    $(".multi_select option").removeAttr("selected");
    //clear all hidden fields that should be cleared
    $(".allow_clear ").val("");

    //uncheck any checkboxes in the dropdowns
    $("input[type=checkbox]").each(function () {
      $(this).removeAttr("checked");
    });

    // clear the results
    $("#searchResultsContent").html("");
  });

  //Terms modal

  $(".main_content,#footer,#front-footer").on(
    "click",
    ".btnPolicyModal",
    function () {
      let type = $(this).attr("data-type");
      $("#termsModal #modal-content").html("").addClass("preloading");
      $.ajax({
        type: "POST",
        url: "/ajax/modal" + type + "/",
      }).done(function (data) {
        $("#termsModal #modal-content").removeClass("preloading").html(data);
        let popup = new Foundation.Reveal($("#termsModal"));
        popup.open();
      });
    }
  );

  //========================================================//
  // VIEW POSTER
  //========================================================//
  $(".main_content").on("click", ".btnViewPoster", function (e) {
    e.preventDefault();
    let id = $(this).data("id");
    let target = $(this).data("target");
    let preview = $("#congressPosterContainer").data("preview");
    let preview_param = "";

    if (preview == 1) {
      preview_param = "&preview=true";
    }

    if (target == "_blank") {
      $.ajax({
        type: "POST",
        url: "/ajax/getBaseUrl/",
      }).done(function (url) {
        let win = window.open(
          url + "/poster/?id=" + id + preview_param,
          target,
          "noopener, noreferrer"
        );

        if (win) {
          //Browser has allowed it to be opened
          win.focus();
        } else {
          //Browser has blocked it
          alert("Please allow popups for this website");
        }
      });
    } else {
      window.location.href = "/poster/?id=" + id + preview_param;
    }
  });

  $(".main_content,#myModal").on("click", ".selectAll", function (e) {
    e.preventDefault();
    let id = $(this).attr("data-id");
    copyToClipboardMsg(document.getElementById(id), "msg");
  });

  //TOGGLE FLAG
  $(".main_content").on("click", ".toggle_flag", function () {
    let id = $(this).attr("data-id");
    let id_name = $(this).attr("data-id_name");
    let field = $(this).attr("data-field");
    let table = $(this).attr("data-table");
    let toggle = $(this).prop("checked");

    let value = 0;
    if (toggle === true) {
      value = 1;
    }

    $.ajax({
      type: "POST",
      url: "/ajax/updateToggle/",
      data: {
        id: id,
        id_name: id_name,
        field: field,
        value: value,
        table: table,
      },
    });
  });

  //TOGGLE SWITCH
  $(".main_content,#myModal").on(
    "click",
    ".toggle-switch.standard,.toggle-switch.activate-switch",
    function () {
      //check if this is on or off
      let toggle = $(this).prop("checked");
      let text_on = $(this).data("text-on");
      let text_off = $(this).data("text-off");

      //if the state is currently on then turn it off
      if (toggle === false) {
        $(this)
          .next(".custom-slider")
          .attr("title", text_on)
          .removeClass("on")
          .addClass("off");
        $(this)
          .next(".custom-slider")
          .find("span.disc")
          .animate({ left: "10%" });
        $(this).parent().next(".toggle-text").text(text_on);
      } else {
        $(this)
          .next(".custom-slider")
          .attr("title", text_off)
          .removeClass("off")
          .addClass("on");
        $(this)
          .next(".custom-slider")
          .find("span.disc")
          .animate({ left: "70%" });
        $(this).parent().next(".toggle-text").text(text_off);
      }
    }
  );

  // HIDE ALL HIDEABLE ELEMENTS
  $(".hideable").hide();
  //INITIALIZE THE FOUNDATION MULTI SELECT DROP DOWNS----------- <
  init_multiselect();
  //END INIT MULTI

  //INITIALIZE ANY DATE FIELD SO IT USES DATEPICKER  ----------- <

  if ($(".date").length > 0 || $(".date_future").length > 0) {
    init_date_picker();
  }
});
